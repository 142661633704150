import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { GlobalStyles } from 'components/GlobalStyles';
import { AuthContext } from 'contexts/AuthContext';
import { User } from 'types';
import routes from './routes';

const queryClient = new QueryClient();
function AppRoutes() {
  return useRoutes(routes);
}
const theme = extendTheme({
  styles: {
    global: {
      // Apply height to html and body
      'html, body, #root': {
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  colors: {
    brand: {
      red: '#F62460',
      black: '#363636',
      salmon: '#E06F90',
      ChocolateCosmos: '#590d22',
      Claret: '#800f2f',
      Amaranth: '#a4133c',
      RoseRed: '#c9184a',
      BrightPink: '#ff4d6d',
      Crayola: '#ff758f',
      SalmonPink: '#ff8fa3',
      CherryBlossom: '#ffb3c1',
      Pink: '#ffccd5',
      Lavender: '#fff0f3',
    },
  },
});
export const App = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(
    JSON.parse(localStorage.getItem('user') ?? 'null')
  );
  const [expiryTime, setExpiryTime] = useState<number | null>(
    JSON.parse(localStorage.getItem('expiryTime') ?? '-1')
  );
  return (
    <ChakraProvider theme={theme}>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
          <AuthContext.Provider
            value={{ currentUser, setCurrentUser, expiryTime, setExpiryTime }}
          >
            <Router>
              <AppRoutes />
            </Router>
          </AuthContext.Provider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};
