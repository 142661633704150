import {
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  useColorMode,
  useColorModeValue,
  Icon,
  Button,
  useBreakpointValue,
  IconButton,
} from '@chakra-ui/react';
import {
  FiMoon,
  FiSun,
  FiUser,
  FiUsers,
  FiLogOut,
  FiCornerDownRight,
  FiPlus,
} from 'react-icons/fi';
import { useAuth } from 'contexts/AuthContext';
import { useGoto } from 'hooks/useGoto';
import { useLogInLogOut } from 'hooks/useLogInLogOut';

export const ProfileMenu = () => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('Dark', 'Light');
  const SwitchIcon = useColorModeValue(FiMoon, FiSun);
  const { login, logout } = useLogInLogOut({ logOutRedirectTo: '/login' });
  const auth = useAuth();
  const { goto, gotoHandler } = useGoto();
  const button = (
    <Button
      size={{ base: 'sm', md: 'md' }}
      variant="outline"
      onClick={() => goto('/create/new')}
    >
      Create Event
    </Button>
  );
  const iconButton = (
    <IconButton
      onClick={() => goto('/create/new')}
      icon={<Icon as={FiPlus}></Icon>}
      aria-label={'Create Event'}
      size="sm"
      borderRadius="full"
      variant="outline"
    >
      Create Event
    </IconButton>
  );
  const createEvent = useBreakpointValue({ base: iconButton, sm: button });
  return (
    <>
      {!auth?.currentUser?.managingOrgs && createEvent}
      <Menu>
        <MenuButton
          key={auth?.currentUser?.picture}
          as={Avatar}
          size={{ base: 'xs', md: 'sm' }}
          name={auth?.currentUser?.name}
          src={auth?.currentUser?.picture}
        ></MenuButton>
        <MenuList>
          <MenuGroup title="Profile">
            {!auth?.currentUser ? (
              <MenuItem onClick={login}>
                <Icon
                  as={FiUser}
                  margin="0px 0.375rem 0 0"
                  boxSize={4}
                  color="brand.RoseRed"
                />
                Log In
              </MenuItem>
            ) : (
              <>
                <MenuItem onClick={gotoHandler(`/user/dashboard`)}>
                  <Icon
                    as={FiUser}
                    margin="0px 0.375rem 0 0"
                    boxSize={4}
                    color="brand.RoseRed"
                  />
                  Dashboard
                </MenuItem>
                <MenuItem onClick={logout}>
                  <Icon
                    as={FiLogOut}
                    margin="0px 0.375rem 0 0"
                    boxSize={4}
                    color="brand.RoseRed"
                  />
                  Log out
                </MenuItem>
              </>
            )}
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title="Organisation">
            <MenuItem onClick={gotoHandler(`/create/new`)}>
              <Icon
                as={FiUsers}
                margin="0px 0.375rem 0 0"
                boxSize={4}
                color="brand.RoseRed"
              />
              Create Organization
            </MenuItem>
            {auth?.currentUser?.managingOrgs?.map(managingOrg => (
              <MenuItem
                key={managingOrg.id}
                onClick={gotoHandler(`/org/${managingOrg.id}/dashboard`)}
              >
                <Icon
                  as={FiCornerDownRight}
                  margin="0px 0.375rem 0 0"
                  boxSize={4}
                  color="brand.RoseRed"
                />
                {managingOrg.name}
              </MenuItem>
            ))}
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title="Help">
            <MenuItem onClick={toggleColorMode}>
              <Icon
                as={SwitchIcon}
                margin="0px 0.375rem 0 0"
                boxSize={4}
                color="brand.RoseRed"
              />
              {text} Mode
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </>
  );
};
