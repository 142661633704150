export type SpeakerProfile = {
  id: string;
  name: string;
  email: string;
  picture: string;
  country: string;
  bio: string;
  linkedIn: string;
};

export type User = {
  name: string;
  email: string;
  picture: string;
  managingOrgs: Manages[];
};

export type EventType =
  | 'WALK_IN'
  | 'JUST_REGISTER'
  | 'BY_INVITATION'
  | 'BUY_TICKET'
  | 'INVITEES_BUY_TICKET'
  | 'INVITEES_REGISTER';

export interface EventTypeOption {
  id: EventType;
  title: string;
  description: string;
  isPro?: boolean;
}
export type Event = {
  id: string;
  name: string;
  eventType: EventType;
  organisationId: string;
  organisationName: string;
  category?: string;
  country: SupportedCountries;
  location: string;
  eventLocationType: EventLocationType;
  placeId?: string;
  address?: string;
  howToJoin?: string;
  mapUrl?: string;
  meetingLink?: string;
  eventDate: string;
  startTime: string;
  endTime: string;
  description: string;
  image: string;
  shortLink?: string;
  maxRegistrations: number;
};
export type UserEventSummery = {
  id: string;
  name: string;
  organisationId: string;
  image: string;
  eventDate: string;
  startTime: string;
  form?: string;
};

export type UserEventInvitation = {
  id: string;
  name: string;
  status: string;
  userId: string;
  organisationId: string;
  image: string;
  eventDate: string;
  startTime: string;
  invitationCode?: string;
  form?: string;
  formId: string;
};

export type Form = {
  id: string;
  name: string;
  status: string;
  schema: any;
  uiSchema: any;
  responsesCount?: number;
  invitationsCount?: number;
  confirmedCount?: number;
  declinedCount?: number;
  revokedCount?: number;
  checkedInCount?: number;
  responsesCountLimit?: number;
  registrationType?: string;
  invitationMessage?: string;
  invitationMessageTitle?: string;
  regMessage?: string;

  externalUrl?: string;
  maxedOutMessage?: string;
  closedMessage?: string;
};

export type ResponseKeys = string[];

export type ResponseProfile = {
  id: string;
  rate: string;
  status: string;
  username: string;
  picture: string;
  createdAt: string;
};
export type Response = {
  id: string;
  userId: string;
  formId: string;
  eventId: string;
  organisationId: string;
  status: string;
  note?: string;
  rate: number;
  username: string;
  picture: string;
  createdAt: string;
  data: any;
};

export type EventLocationType = 'Online' | 'Physical' | 'Hybrid';
export type SupportedCountries = 'SG' | 'LK';
export type SupportedCountryFilter = 'Anywhere' | 'SG' | 'LK';

export type ManagerRoles = 'Admin' | 'Viewer';
export type Organisation = {
  id: string;
  name: string;
  intro?: string;
  link?: string;
};
export type Manages = {
  id: string;
  name: string;
  userId: string;
  username: string;
  role: ManagerRoles;
};

export type AuthResponse = {
  token: string;
  expiryTime: number;
  user: User;
  managingOrgs: Manages[];
};

export const countryCodes: Record<SupportedCountries, string> = {
  SG: 'Singapore',
  LK: 'Sri Lanka',
};
export const countryOptions: Record<SupportedCountryFilter, string> = {
  ...countryCodes,
  Anywhere: 'Global',
};

export type OrganisationInterest = {
  aboutOrganisation: string;
  contactNumber: string;
  country: string;
  organisationName: string;
  organisationSlug: string;
  primaryContactEmail: string;
  privacyPolicy: boolean;
  secondaryContactEmail: string;
  terms: boolean;
};
